import React, { useContext, createContext, useState, PropsWithChildren } from 'react';
import { Button } from '@/components/common';
import Modal from '@/components/common/Modal';
import { ModalSize, ModalTheme, modalTheme } from '@/components/common/Modal/Modal.style';

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  size?: ModalSize;
  theme?: ModalTheme;
}

interface ModalContextType {
  openModal: (data: ModalProps) => void;
}

const ModalContext = createContext <ModalContextType>({} as ModalContextType);

const ModalProvider = ({ children: bodyChildren }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<ModalProps>({} as ModalProps);

  const { children, confirmText, cancelText, onConfirm, size, theme = 'default' } = modalData;

  const openModal = (data: ModalProps) => {
    setIsOpen(true);
    setModalData(data);
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      {bodyChildren}
      {isOpen && (
        <Modal
          title= ""
          headLess
          size={size}
          theme={theme}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          footer={(
            <>
              {cancelText && (
                <Button
                  theme="grey"
                  backgroundColor="blueGrey10"
                  bordered
                  borderColor="blueGrey75"
                  color="black"
                  width={100}
                  onClick={() => setIsOpen(false)}>{cancelText}
                </Button>
              )}
              {confirmText && (
                <Button
                  theme={theme}
                  backgroundColor={modalTheme[theme]}
                  bordered
                  borderColor={modalTheme[theme]}
                  color="white"
                  width={100}
                  onClick={() => {
                    onConfirm ? onConfirm() : () => {};
                    setIsOpen(false);
                  }}>{confirmText}
                </Button>
              )}
            </>
          )
          }>
          {children}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('Modal Provider가 핋요합니다.');
  }

  return context;
};

export { ModalProvider, useModal };
