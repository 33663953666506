import { GetServerSidePropsContext } from 'next';
import { createServerSideClient, heraClient } from './http';
import { CurrentUser, Userable } from '@/exportables/models/user.model';

export const getCurrentUser = async (ctx?: GetServerSidePropsContext) => {
  const axioClient = ctx ? createServerSideClient(ctx) : heraClient;
  const { data } = await axioClient.get<CurrentUser>('/api/v1/users/current');
  return data;
};

export const block = async (blockedUserId: Userable['userId']) => {
  const { data } = await heraClient.post(`/api/blocked_users`, { blockedUserId });
  return data;
};
