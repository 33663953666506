import React, { useCallback, useEffect } from 'react';
import { ModalBody, ModalClose, ModalContent, ModalDescription, ModalFooter, ModalHeader, ModalHeaderTop, ModalOverlay, ModalTitle } from './Modal.style';
import type { ModalSize, ModalTheme } from './Modal.style';
import CloseIcon from '@/assets/svgs/ic-clear.svg';
interface ModalProps {
  size?: ModalSize;
  theme?: ModalTheme;
  title: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  zIndex?: number;
  headLess?: boolean;
  preventClickOverlay?: boolean;
}

const Modal = ({
  size = 'lg',
  isOpen,
  onClose,
  title,
  description,
  children,
  footer,
  zIndex,
  headLess,
  preventClickOverlay,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  const handleClickOverlay = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (preventClickOverlay) {
      return;
    }
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [preventClickOverlay, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <ModalOverlay onClick={handleClickOverlay} zIndex={zIndex}>
        <ModalContent size={size}>
          {!headLess && (
            <ModalHeader>
              <ModalHeaderTop>
                <ModalTitle>{title}</ModalTitle>
                <ModalClose onClick={onClose}>
                  <CloseIcon />
                </ModalClose>
              </ModalHeaderTop>
              {description && (
                <ModalDescription>
                  {description}
                </ModalDescription>
              )}
            </ModalHeader>
          )}
          <ModalBody>
            {children}
          </ModalBody>
          {footer && (
            <ModalFooter>
              {footer}
            </ModalFooter>
          )}
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default Modal;
