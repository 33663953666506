import { PropsWithChildren } from 'react';
import { ErrorMessage } from './FormErrorMessage.style';

const FormErrorMessage = ({ children }: PropsWithChildren) => {
  return (
    <ErrorMessage>
      {children}
    </ErrorMessage>
  );
};

export default FormErrorMessage;
