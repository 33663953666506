import { css, Global, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaBreakPoints, THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

const modalSize = {
  sm: remCalc('484'),
  sm2: remCalc('500'),
  md: remCalc('600'),
  lg: remCalc('992'),
};

export type ModalSize = keyof typeof modalSize;

export const modalTheme = {
  default: 'blue500' as keyof typeof THEME_COLORS,
  danger: 'red600' as keyof typeof THEME_COLORS,
};

export type ModalTheme = keyof typeof modalTheme;

interface ModalOverlayProps {
  zIndex?: number;
}
interface ModalContentProps {
  size: ModalSize;
}
interface ModalFooterProps {
  align?: 'right' | 'center';
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slide = keyframes`
  0% {
    transform: translateY(${remCalc('-50')});
  }
  100% {
    transform: translateY(0);
  }
`;

const globalStyle = css`
  html.scroll-lock {
    overflow: hidden;

    body {
      overflow: hidden;
    }
  }
`;

export const GlobalStyle = () => {
  return <Global styles={globalStyle} />;
};

export const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex ? zIndex : 101};
  animation: ${fadeIn} 0.15s linear;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  padding: ${remCalc('0 15')};

  @media ${mediaBreakPoints.sm_down} {
    padding: ${remCalc('0 8')};
  }
`;

export const ModalContent = styled.div<ModalContentProps>`
  box-sizing: border-box;
  width: 100%;
  max-width: ${({ size }) => modalSize[size]};
  animation: ${slide} 0.15s linear;
  background-color: ${THEME_COLORS.white};
  border-radius: ${remCalc('15')};
  margin: ${remCalc('40')} auto;

  @media ${mediaBreakPoints.lg_down} {
    margin: ${remCalc('24')} auto;
    max-width: ${remCalc('640')};
  }

  @media ${mediaBreakPoints.md_down} {
    max-width: ${remCalc('540')};
  }

  @media ${mediaBreakPoints.sm_down} {
    margin: ${remCalc('8')} auto;
  }
`;

export const ModalHeader = styled.div`
  padding: ${remCalc('16 32')};
  position: relative;
  border-bottom: 1px solid #E7E7E7;

  @media ${mediaBreakPoints.lg_down} {
    padding: ${remCalc('16 32')};
  }

  @media ${mediaBreakPoints.md_down} {
    padding: ${remCalc('16 24')};
  }
`;

export const ModalHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled.div`
  color: ${THEME_COLORS.blueGrey900};
  font-size: ${remCalc('16')};
  line-height: ${remCalc('24')};
  font-weight: 700;
`;

export const ModalDescription = styled.div`
  color: ${THEME_COLORS.blueGrey900};
  line-height: 150%;
  letter-spacing: -0.009em;
`;

export const ModalClose = styled.div`
  cursor: pointer;
  padding: ${remCalc('10')};
  position: absolute;
  top: ${remCalc('12')};
  right: ${remCalc('16')};
  
  svg {
    width: ${remCalc('14')};
    height: ${remCalc('14')};
    fill: ${THEME_COLORS.black};
  }
`;

export const ModalBody = styled.div`
  padding: ${remCalc('24 32 32')};

  .confirm-content {
    font-weight: 500;
    a {
      color: ${THEME_COLORS.blue500};
    }
  }

  @media ${mediaBreakPoints.md_down} {
    padding: ${remCalc('24')}
  }
`;

export const ModalFooter = styled.div<ModalFooterProps>`
  display: flex;
  justify-content: ${({ align }) => align === 'center' ? 'center' : 'flex-end'};
  padding: ${remCalc('0 24 24')};
  gap: ${remCalc('8')};

  @media ${mediaBreakPoints.lg_down} {
    padding: ${remCalc('24')};
  }

  @media ${mediaBreakPoints.md_down} {
    padding: ${remCalc('16')}
  }
`;
