const REM_BASE = 16;
const ONLY_NUMBER_AND_SPACE_REGEX = /^[0-9 -\.]+$/;

const convertToRem = (value: number, baseValue = REM_BASE): string => {
  if (baseValue === 0) throw new Error('0으로 나눌 수 없습니다.');

  const rem = value / baseValue;
  return rem === 0 ? '0' : `${rem}rem`;
};

export const remCalc = (values: string, baseValue = REM_BASE): string => {
  if (!values?.match(ONLY_NUMBER_AND_SPACE_REGEX)) throw new Error('숫자와 공백 이외의 문자는 변환할 수 없습니다.');

  const rems = values
    .replace(/\s\s+/g, ' ')
    .split(' ')
    .map((pixel) => convertToRem(+pixel, baseValue));

  return rems.join(' ');
};

// NOTE: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export const createUUID = () => {
  let d = new Date().getTime();
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};

export const generateRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const validateEmailFormat = (email: string) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(mailformat);
};
