import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode,
};

const Layout = ({ children }: LayoutProps) => {
  return (
      <main style={{ width: '100%', overflowX: 'hidden' }}>{children}</main>
  );
};

export default Layout;
