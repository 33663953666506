import styled from '@emotion/styled';
import { remCalc } from '@/exportables/utils';

export const ErrorMessage = styled.div`
  color: #F03D0C;
  font-size: ${remCalc('13')};
  line-height: ${remCalc('19')};
  display: flex;
  align-items: center;
  gap: ${remCalc('6')};

  svg {
    width: ${remCalc('12')};
    height: ${remCalc('12')};
  }
`;
