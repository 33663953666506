import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { belowMobile, mdDown, THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

const HomeLogo = styled.div`
  padding: ${remCalc('16 20 20 20')};
  background-color: ${THEME_COLORS.white};
  display: inline-block;

  img {
    width: ${remCalc('24')};
    height: ${remCalc('24')};
  }

  @media (max-width: ${mdDown}px) {
    display: none;
  }
`;

const Layout = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: ${remCalc('0 40')};
  color: ${THEME_COLORS.white};
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .image {
    width: ${remCalc('96')};
    margin-top: 14vh;

    @media (max-width: ${mdDown}px) {
      margin-top: 0;
    }

    ${belowMobile(css`
      width: ${remCalc('48')};
      height: auto;
    `)}
  }
  
  .title {
    margin-top: ${remCalc('40')};
    font-size: ${remCalc('40')};
    font-weight: bold;
    letter-spacing: -0.009em;
    line-height: 1.4;
    word-wrap: break-word;
    word-break: keep-all;

    @media (max-width: ${mdDown}px) {
      font-size: ${remCalc('34')};
    }

    ${belowMobile(css`
      margin-top: ${remCalc('32')};
      font-size: ${remCalc('28')};
    `)}
  }

  .sub-title {
    margin-top: ${remCalc('16')};
    color: ${THEME_COLORS.white};
    font-size: ${remCalc('20')};
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    letter-spacing: -0.009em;
    line-height: 1.6;

    @media (max-width: ${mdDown}px) {
      font-size: ${remCalc('16')};
    }
    
    ${belowMobile(css`
      margin-top: ${remCalc('16')};
    `)}
  }
`;

const BackButton = styled.button`
  display: inline-block;
  padding: ${remCalc('8 16')};
  border: ${remCalc('1')} solid transparent;
  border-color: ${THEME_COLORS.blueGrey50};
  margin-top: ${remCalc('64')};
  background-color: ${THEME_COLORS.blueGrey50};
  border-radius: ${remCalc('4.8')};
  color: ${THEME_COLORS.indigo500};
  font-size: ${remCalc('26')};
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: ${remCalc('38')};

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${mdDown}px) {
    font-size: ${remCalc('24')};
    line-height: ${remCalc('34')};
  }

  ${belowMobile(css`
    font-size: ${remCalc('22')};
    line-height: ${remCalc('30')};
  `)}
`;

export default {
  BackButton,
  HomeLogo,
  Layout,
};
