declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__?: {
      [key: string]: Function | Map<number, object> | object,
    };
  }
}

export const disableReactDevTools = () => {
  if (typeof window === 'undefined' || !window?.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renderers') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      continue;
    }

    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = () => {};
    }
  }
};
