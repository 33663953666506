import styled from '@emotion/styled';
import { THEME_COLORS } from '@/exportables/styles';
import { remCalc } from '@/exportables/utils';

type BoxProps = {
  width?: string,
};

export const Box = styled.div<BoxProps>`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${remCalc('46')};
  background: ${THEME_COLORS.blueGrey10};
  border: ${remCalc('1')} #E6EEF5 solid;
  border-radius: ${remCalc('5')};
  color: ${THEME_COLORS.blueGrey900};

  &:hover, &:focus-within {
    outline: none;
    border: ${remCalc('1')} solid ${THEME_COLORS.blue500};
    box-shadow: inset ${remCalc('0 0 0 1')} ${THEME_COLORS.blue500};
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding: ${remCalc('11 20')};
  font-size: ${remCalc('16')};

  &[type='password'] {
    padding: ${remCalc('11 48 11 20')};
  }
  &[type='date'] {
    cursor: pointer;
  }

  &::placeholder {
    line-height: ${remCalc('24')};
    font-size: ${remCalc('15')};
    color: ${THEME_COLORS.blueGrey900};
    opacity: 0.3;
  }
`;
