import ProgrammersLogo from '@/assets/images/apple-icon.png';

export const requestPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission === 'granted';
};

type NotificationOptions = {
  title: string,
} & Partial<Notification>;

export const spawnNotification = ({
  title,
  ...options
}: NotificationOptions) => {
  if (Notification.permission !== 'granted') return;

  const notification = new Notification(title, {
    icon: ProgrammersLogo.src,
    image: ProgrammersLogo.src,
    vibrate: [200, 100, 200],
    ...options,
  });

  notification.addEventListener('click', (event) => {
    options?.onclick?.call(notification, event);
    notification.close();
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      notification.close();
    }
  });

  return notification;
};
