interface Manifest {
  [key: string]: {
    name: string,
    manifest: string,
    fileName: string,
    css: string,
  };
}

const heraClientApps: Manifest = {
  gnb: {
    name: 'gnb',
    manifest: 'manifest.edu-service-app-components.json',
    fileName: 'main.js',
    css: 'css',
  },
  activity: {
    name: 'activity',
    manifest: 'manifest.profile-app-activity.json',
    fileName: 'main.js',
    css: 'css',
  },
  banner: {
    name: 'banner',
    manifest: 'manifest.community-app-banner-vertical.json',
    fileName: 'main.js',
    css: 'css',
  },
};

type AppName = keyof typeof heraClientApps;
export class HeraClientAppInfo {
  constructor(private appName: AppName, private manifest: Record<string, string>) {}

  async getScriptContent() {
    const res = await fetch(this.manifest[heraClientApps[this.appName].fileName]);

    return await res.text();
  }

  getCssUrl() {
    return this.manifest[heraClientApps[this.appName].css];
  }

  static async instanceOf(appName: AppName) {
    const url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/${heraClientApps[appName].manifest}` as string;
    const res = await fetch(url);

    const manifest = await res.json();

    return new HeraClientAppInfo(appName, manifest);
  }
}

export const getSsrLayout = async (part: 'header' | 'footer'): Promise<{ body: string }> => {

  const endpointTarget = {
    header: '/ssr/main-app-header',
    footer: '/ssr/main-app-gnb/footer',
  };

  const endpoint = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}${endpointTarget[part]}`;
  const res = await fetch(endpoint);

  return await res.json();
};
