import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import { Box, InputStyled } from './Input.style';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  width?: string;
}

const Input = forwardRef<HTMLInputElement, Props>(({ type, ...props }: Props, ref) => {
  return (
    <Box width={props.width}>
      <InputStyled
        {...props}
        type={type}
        ref={ref}
      />
    </Box>
  );
});

export default Input;
