import Toastify from 'toastify-js';

export const showToast = (message: string) => {
  Toastify({
    text: message,
    duration: 3000,
    position: 'center',
    style: {
      background: '#000000',
      cursor: 'default',
      fontWeight: '400',
      boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
    },
    offset: {
      x: '',
      y: '1rem',
    },
  }).showToast();
};
