import type { PropsWithChildren } from 'react';
import { FormControl } from './FormControl.style';

interface Props {
  required?: boolean;
  className?: string;
}

const FormControlContainer = ({ children, required, className }: PropsWithChildren<Props>) => {
  return (
    <FormControl required={required} className={className}>
      {children}
    </FormControl>
  );
};

export default FormControlContainer;
