import Image from 'next/image';
import Style from './Unauthorized.style';
import BackgroundImage from './bg-500.jpg';
import BrokenImage from './ic-broken-image-96.png';
import HomeImage from './ic-hall-24-3F51B5.png';
import { getHeraBaseUrl } from '@/exportables/utils';

const HERA_BASE_URL = getHeraBaseUrl();

const Unauthorized = () => {
  const moveToHome = () => window.location.assign(HERA_BASE_URL!);

  return (
    <Style.Layout
      style={{
        backgroundImage: `url(${BackgroundImage.src})`,
      }}
    >
      <Style.HomeLogo onClick={moveToHome}>
        <Image src={HomeImage} alt="logo" />
      </Style.HomeLogo>
      <Image
        className="image"
        src={BrokenImage}
        alt="not authorized"
        width={96}
      />
      <h1 className="title">해당 페이지에 접근할 권한이 없습니다.</h1>
      <h2 className="sub-title">401 Unauthorized</h2>
      <Style.BackButton onClick={() => history.back()}>
        이전 페이지 가기
      </Style.BackButton>
    </Style.Layout>
  );
};

export default Unauthorized;
