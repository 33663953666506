import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { CurrentUser, UserInfo } from '@/exportables/models/user.model';
import { QUERY_KEY } from '@/exportables/services/queryKey';
import { getCurrentUser } from '@/exportables/services/user.api';

type UserContextType = Pick<CurrentUser, 'timeZone'> & {
  isFetching: boolean,
  isLoading: boolean,
  isLoggedIn: boolean,
  userInfo: UserInfo | null,
};

const UserContext = createContext<UserContextType>(null!);

const isProduction = process.env.NEXT_PUBLIC_STAGE === 'production';

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.user],
    queryFn: () => getCurrentUser(),
    initialData: {
      isLoggedIn: false,
      timeZone: 'Asia/Seoul',
      userInfo: null,
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { isLoggedIn, timeZone, userInfo } = data;

  useEffect(() => {
    if (!isLoggedIn || !isProduction) return;

    const script = document.createElement('script');
    const scriptText = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ user_id: "${userInfo?.id}" });
    `);

    script.appendChild(scriptText);
    document.body.appendChild(script);
  }, [isLoggedIn, userInfo?.id]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        isLoggedIn,
        isFetching,
        timeZone,
        userInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
