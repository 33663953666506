import { css } from '@emotion/react';
import { THEME_COLORS } from '@/exportables/styles/colors';
import { mediaBreakPoints, containerPadding, tabletSizeBreak, webSizeBreak, containerMaxWidth, articleMaxWidth, mdDown, lgDown } from '@/exportables/styles/variables';
import { remCalc } from '@/exportables/utils';

type FlexProps = {
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit' | 'stretch',
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit',
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit',
};

type FontProps = {
  size?: number,
  weight?: 'normal' | 'bold' | number,
  color?: typeof THEME_COLORS[keyof typeof THEME_COLORS],
  opacity?: number,
  lineHeight?: string,
};

type EllipsisProps = {
  lineCount?: number,
  lineHeight?: number,
};

export const belowMobile = (cssContent: any) => css`
  @media only screen and ${mediaBreakPoints.sm_down} {
    ${cssContent}
  }
`;

export const belowLgDown = (cssContent: any) => css`
  @media only screen and (max-width: ${remCalc(lgDown)}) {
    ${cssContent}
  }
`;

export const belowMdDown = (cssContent: any) => css`
  @media only screen and (max-width: ${remCalc(mdDown)}) {
    ${cssContent}
  }
`;

export const belowTablet = (cssContent: any) => css`
  @media only screen and (max-width: ${remCalc(tabletSizeBreak)}) {
    ${cssContent}
  }
`;

export const belowWeb = (cssContent: any) => css`
  @media only screen and (max-width: ${remCalc(webSizeBreak)}) {
    ${cssContent}
  }
`;

export const webOnly = (cssContent: any) => css`
  @media only screen and (min-width: ${remCalc(webSizeBreak)}) {
    ${cssContent}
  }
`;

export const flexContainer = (
  justifyContent?: FlexProps['justifyContent'],
  alignItems?: FlexProps['alignItems'],
  direction?: FlexProps['direction'],
) => css`
  display: flex;
  flex-direction: ${direction};
  align-items: ${alignItems};
  justify-content: ${justifyContent};
`;

export const flexCenter = css`
  ${flexContainer('center', 'center')}
`;

export const flexCenterX = css`
  ${flexContainer('center')}
`;

export const flexCenterY = css`
  ${flexContainer('initial', 'center')}
`;

export const flexStartX = css`
  ${flexContainer('flex-start')}
`;

export const flexEndX = css`
  ${flexContainer('flex-end', 'initial')}
`;

export const flexColumnStartY = css`
  ${flexContainer('initial', 'flex-start', 'column')}
`;

export const flexColumnCenterX = css`
  ${flexContainer('initial', 'center', 'column')}
`;

export const flexColumnCenterY = css`
  ${flexContainer('center', 'initial', 'column')}
`;

export const flexColumnCenterAll = css`
  ${flexContainer('center', 'center', 'column')}
`;

export const flexBetweenX = css`
  ${flexContainer('space-between')}
`;

export const flexBetweenY = css`
  ${flexContainer('space-between', 'initial', 'column')}
`;

export const flexWrap = css`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const font = (
  size?: FontProps['size'],
  weight?: FontProps['weight'],
  color?: FontProps['color'],
  opacity?: FontProps['opacity'],
  lineHeight?: FontProps['lineHeight'],
) => css`
  color: ${color};
  font-family: 'Inter', 'Noto Sans KR', sans-serif;
  font-size: ${remCalc(`${size}`)};
  font-weight: ${weight};
  line-height: ${lineHeight};
  opacity: ${opacity};
`;

export const TextH1 = css`
  ${font(52, 700, THEME_COLORS.textHeading, 1, '120%')}

  ${belowTablet(css`
    ${font(40, 700, THEME_COLORS.textHeading, 1, '120%')}
  `)}

  ${belowMobile(css`
    ${font(32, 700, THEME_COLORS.textHeading, 1, '120%')}
  `)}
`;

export const TextH2 = css`
  ${font(16, 700, THEME_COLORS.black, 1, '140%')}
`;

export const TextH3 = css`
  ${font(28, 600, THEME_COLORS.textHeading, 1, '120%')}

  ${belowTablet(css`
    ${font(24, 600, THEME_COLORS.textHeading, 1, '120%')}
  `)}
`;

export const TextH4 = css`
  ${font(24, 600, THEME_COLORS.textHeading, 1, '120%')}

  ${belowTablet(css`
    ${font(20, 600, THEME_COLORS.textHeading, 1, '120%')}
  `)}
`;

export const TextButton = css`
  ${font(18, 700, THEME_COLORS.textHeading, 1, '130%')}

  ${belowTablet(css`
    ${font(14, 700, THEME_COLORS.textHeading, 1, '130%')}
  `)}
`;

export const TextParagraph = css`
  ${font(14, 400, THEME_COLORS.grey20, 1, '140%')}
`;

export const textBreak = css`
  word-break: break-all;
  word-wrap: break-word;
`;

export const textEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const textLink = css`
  color: ${THEME_COLORS.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const textCenter = css`
  text-align: center;
`;

export const ellipsis = (
  lineCount?: EllipsisProps['lineCount'],
  lineHeight?: EllipsisProps['lineHeight'],
) => css`
  display: box;
  overflow: hidden;
  height: calc(${remCalc(`${lineHeight}`)} * ${lineCount});
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineCount}; 
  line-height: ${remCalc(`${lineHeight}`)};
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const displayWebOnly = css`
  ${belowTablet(css`
    display: none !important;
  `)}
`;

export const displayMobileOnly = css`
  ${webOnly(css`
    display: none !important;
  `)}
`;

export const baseContainer = css`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  padding: ${containerPadding.y} ${containerPadding.x};

  ${webOnly(css`
    max-width: ${remCalc(containerMaxWidth)};
  `)}

  ${belowWeb(css`
    max-width: ${remCalc(containerMaxWidth)};
  `)}

  ${belowTablet(css`
    padding: ${containerPadding.yTablet} ${containerPadding.xTablet};
  `)}

  ${belowMobile(css`
    padding: ${containerPadding.yMobile} ${containerPadding.xMobile};
  `)}
`;

export const articleContainer = css`
  max-width: ${remCalc(articleMaxWidth)};
  margin-left: auto;
  margin-right: auto;
  align-self: center;

  ${belowTablet(css`
    padding: ${remCalc('0 32')};
  `)}

  ${belowMobile(css`
    padding: ${remCalc('0 20')};
  `)}
`;

export const baseContainerFixedWidth = css`
  ${baseContainer}

  ${belowWeb(css`
    max-width: 100vw;
  `)}
`;
