import styled from '@emotion/styled';
import { remCalc } from '@/exportables/utils';

const Label = styled.label`
  font-weight: 500;
  color: #424242;
  font-size: ${remCalc('13')};
  line-height: ${remCalc('24')};
  text-align: left;
`;

export default Label;
