import { remCalc } from '@/exportables/utils';

export const zIndex = 1000;
export const webSizeBreak = '1440';
export const tabletSizeBreak = '1199';
export const lgDown = '991';
export const mdDown = '767';

export const breakpoints = {
  xs_down: 320,
  sm_down: 575,
  md_down: 767,
  lg_down: 991,
  xl_down: 1199,
  xxl_down: 1599,
  xxxl_down: 1999,
  xs_up: 321,
  sm_up: 576,
  md_up: 768,
  lg_up: 992,
  xl_up: 1200,
  xxl_up: 1600,
  xxxl_up: 2000,
};

export const mediaBreakPoints = {
  xs_down: `(max-width: ${breakpoints.xs_down}px)`,
  sm_down: `(max-width: ${breakpoints.sm_down}px)`,
  md_down: `(max-width: ${breakpoints.md_down}px)`,
  lg_down: `(max-width: ${breakpoints.lg_down}px)`,
  xl_down: `(max-width: ${breakpoints.xl_down}px)`,
  xxl_down: `(max-width: ${breakpoints.xxl_down}px)`,
  xxxl_down: `(max-width: ${breakpoints.xxxl_down}px)`,
  xs_up: `(min-width: ${breakpoints.xs_up}px)`,
  sm_up: `(min-width: ${breakpoints.sm_up}px)`,
  md_up: `(min-width: ${breakpoints.md_up}px)`,
  lg_up: `(min-width: ${breakpoints.lg_up}px)`,
  xl_up: `(min-width: ${breakpoints.xl_up}px)`,
  xxl_up: `(min-width: ${breakpoints.xxl_up}px)`,
  xxxl_up: `(min-width: ${breakpoints.xxxl_up}px)`,
};

export const containerMaxWidth = '1200';
export const articleMaxWidth = '960';
export const containerPadding = {
  y: remCalc('40'),
  x: remCalc('20'),
  yTablet: remCalc('48'),
  xTablet: remCalc('24'),
  yMobile: remCalc('32'),
  xMobile: remCalc('24'),
};
