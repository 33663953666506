// NOTE: 기존 디자인 시스템 테마 색상
export const green50 = '#E8F5E9';
export const green100 = '#C8E6C9';
export const green200 = '#A5D6A7';
export const green300 = '#81C784';
export const green400 = '#66BB6A';
export const green500 = '#4CAF50'; // color_success
export const green700 = '#388E3C';
export const green900 = '#1B5E20';

export const pink50 = '#FCE4EC';
export const pink100 = '#F8BBD0';
export const pink200 = '#F48FB1';
export const pink300 = '#F06292';
export const pink500 = '#E91E63';

export const red25 = '#FCF5F3';
export const red50 = '#FFEBEE';
export const red75 = '#FFDDE0';
export const red100 = '#FFCDD2';
export const red200 = '#EF9A9A';
export const red300 = '#E57373';
export const red500 = '#F44336'; // color_danger
export const red600 = '#F03D0C';
export const red700 = '#D32F2F';
export const red900 = '#B71C1C';
export const red950 = '#8A0D0D';
export const red980 = '#630000';

export const purple50 = '#F3E5F5';
export const purple100 = '#E1BEE7';
export const purple200 = '#CE93D8';
export const purple300 = '#BA68C8';
export const purple400 = '#AB47BC';
export const purple500 = '#9C27B0';
export const purple600 = '#8E24AA';
export const purple700 = '#7B1FA2';
export const purple800 = '#6A1B9A';
export const purple900 = '#4A148C';

export const amber300 = '#FFD54F';
export const amber500 = '#FFC107';
export const amber700 = '#FFA000';
export const amber900 = '#FF6F00';

export const orange50 = '#FFF3E0';
export const orange100 = '#FFE0B2';
export const orange200 = '#FFCC80';
export const orange300 = '#FFB74D';
export const orange400 = '#FFA726';
export const orange500 = '#FF9800';
export const orange700 = '#F57C00';
export const orange900 = '#E65100';

export const deepOrange50 = '#FBE9E7';
export const deepOrange100 = '#FFCCBC';
export const deepOrange200 = '#FFAB91';
export const deepOrange300 = '#FF8A65';
export const deepOrange400 = '#FF7043';
export const deepOrange500 = '#FF5722'; // color_warning
export const deepOrange700 = '#E64A19';
export const deepOrange900 = '#BF360C';

export const deepPurple50 = '#EDE7F6';
export const deepPurple100 = '#D1C4E9';
export const deepPurple200 = '#B39DDB';
export const deepPurple300 = '#9575CD';
export const deepPurple400 = '#7E57C2';
export const deepPurple500 = '#673AB7'; // color_info
export const deepPurple600 = '#5E35B1';
export const deepPurple700 = '#512DA8';
export const deepPurple800 = '#4527A0';
export const deepPurple900 = '#311B92';
export const deepPurple950 = '#051060';

export const indigo300 = '#7986CB';
export const indigo400 = '#5C6BC0';
export const indigo500 = '#3F51B5';
export const indigo700 = '#303F9F';

export const yellow50 = '#FFFAE1';
export const yellow100 = '#FFF9C4';
export const yellow300 = '#FFF176';
export const yellow500 = '#FFEB3B';
export const yellow700 = '#FBC02D';
export const yellow900 = '#F57F17';

export const cyan100 = '#B2EBF2';
export const cyan300 = '#4DD0E1';
export const cyan500 = '#26C6DA';
export const cyan700 = '#0097A7';
export const cyan900 = '#006064';

export const teal50 = '#E0F2F1';
export const teal100 = '#B2DFDB';
export const teal200 = '#80CBC4';
export const teal300 = '#4DB6AC';
export const teal400 = '#26A69A';
export const teal500 = '#009688';
export const teal700 = '#00796B';
export const teal900 = '#004D40';

export const brown50 = '#EFEBE9';
export const brown100 = '#D7CCC8';
export const brown200 = '#BCAAA4';
export const brown300 = '#A1887F';
export const brown400 = '#8D6E63';
export const brown500 = '#795548';
export const brown700 = '#5D4037';
export const brown900 = '#3E2723';

export const blue50 = '#EEEBFF';
export const blue75 = '#D8DDFF';
export const blue100 = '#BBCAFB';
export const blue200 = '#90B5F9';
export const blue300 = '#5CA8FF';
export const blue400 = '#3292FF';
export const blue500 = '#0078FF';
export const blue600 = '#0061FF';
export const blue700 = '#0053F4';
export const blue800 = '#0044E0';
export const blue900 = '#0038AE';

export const lightBlue50 = '#e1f5fe';
export const lightBlue100 = '#B3E5FC';
export const lightBlue200 = '#81D4FA';
export const lightBlue300 = '#4FC3F7';
export const lightBlue400 = '#29B6F6';
export const lightBlue500 = '#03A9F4';
export const lightBlue600 = '#039BE5';
export const lightBlue700 = '#0288D1';
export const lightBlue800 = '#0277BD';
export const lightBlue900 = '#01579B';

export const blueGrey10 = '#FBFBFD'; // color_background
export const blueGrey20 = '#F7F7FB';
export const blueGrey25 = '#F4F4F8';
export const blueGrey50 = '#E9ECF3'; // color_background:hover,color_default color_code_background
export const blueGrey75 = '#D7E2EB'; // color_border
export const blueGrey100 = '#CDD7E0';
export const blueGrey200 = '#B2C0CC';
export const blueGrey300 = '#98A8B9'; // color_text_secondary
export const blueGrey400 = '#7890A0';
export const blueGrey500 = '#5F7F90';
export const blueGrey600 = '#4F6B81';
export const blueGrey700 = '#44576c';
export const blueGrey800 = '#37485D';
export const blueGrey880 = '#2D4053';
export const blueGrey900 = '#263747'; // color_code_background__dark, color_text__primary
export const blueGrey920 = '#202B3D'; // color_table_background__dark
export const blueGrey940 = '#172334'; // color_border__dark
export const blueGrey990 = '#142029';
export const blueGrey999 = '#0C151C';

export const white = '#ffffff';
export const black = '#000000';

// NOTE 정리 필요
// Primary
// export const blue100 = '#112C3F';

// Text
export const textHeading = '#022946';
export const textParagraph = '#444444';

// Card
export const cardYellow = '#FFFBE3';
export const cardBlue = '#ECF9FF';
export const textCardYellow = '#FF9E2D';
export const textCardBlue = lightBlue500;

// Neutral
export const neutralWhite = '#FEFDFE';
export const neutralBlack = '#02162B';

// Grey Scale
export const grey10 = '#F9FAFB';
export const grey15 = '#E7E7E7';
export const grey20 = '#777777';
export const grey25 = '#F0F0F0';
export const grey30 = '#e6eef5';
export const grey50 = '#DFDFDF';
export const grey100 = '#AEAEAE';
export const navygrey50 = '#214E6980';
export const navygrey100 = '#87959E';

export const THEME_COLORS = {
  // primary: blue100,
  secondary: green100,
  accent: orange100,
  grey: grey100,
  textHeading,
  textParagraph,
  disable: grey25,
  grey30,
  grey50,
  grey25,
  grey10,
  grey15,
  grey20,
  navygrey50,
  navygrey100,
  cardYellow,
  cardBlue,
  textCardYellow,
  textCardBlue,

  primary: blue500,
  success: green500,
  danger: red500,
  warning: deepOrange500,
  info: deepPurple500,
  default: blueGrey50,
  background: blueGrey10,
  backgroundHover: blueGrey50,
  codeBackground: blueGrey50,
  codeBackgroundDark: blueGrey900,
  border: blueGrey75,
  borderDark: blueGrey940,
  textSecondary: blueGrey300,
  textPrimary: blueGrey900,
  textBody: blueGrey700,
  tableBackground: 'rgba(50, 50, 144, .02)',
  tableBackgroundDark: blueGrey920,

  green50,
  green100,
  green200,
  green300,
  green400,
  green500,
  green700,
  green900,
  pink50,
  pink100,
  pink200,
  pink300,
  pink500,
  red25,
  red50,
  red75,
  red100,
  red200,
  red300,
  red500,
  red600,
  red700,
  red900,
  red950,
  red980,
  purple50,
  purple100,
  purple200,
  purple300,
  purple400,
  purple500,
  purple600,
  purple700,
  purple800,
  purple900,
  amber300,
  amber500,
  amber700,
  amber900,
  orange50,
  orange100,
  orange200,
  orange300,
  orange400,
  orange500,
  orange700,
  orange900,
  deepOrange50,
  deepOrange100,
  deepOrange200,
  deepOrange300,
  deepOrange400,
  deepOrange500,
  deepOrange700,
  deepOrange900,
  deepPurple50,
  deepPurple100,
  deepPurple200,
  deepPurple300,
  deepPurple400,
  deepPurple500,
  deepPurple600,
  deepPurple700,
  deepPurple800,
  deepPurple900,
  deepPurple950,
  indigo300,
  indigo400,
  indigo500,
  indigo700,
  yellow50,
  yellow100,
  yellow300,
  yellow500,
  yellow700,
  yellow900,
  cyan100,
  cyan300,
  cyan500,
  cyan700,
  cyan900,
  teal50,
  teal100,
  teal200,
  teal300,
  teal400,
  teal500,
  teal700,
  teal900,
  brown50,
  brown100,
  brown200,
  brown300,
  brown400,
  brown500,
  brown700,
  brown900,
  blue50,
  blue75,
  blue100,
  blue200,
  blue300,
  blue400,
  blue500,
  blue600,
  blue700,
  blue800,
  blue900,
  lightBlue50,
  lightBlue100,
  lightBlue200,
  lightBlue300,
  lightBlue400,
  lightBlue500,
  lightBlue600,
  lightBlue700,
  lightBlue800,
  lightBlue900,
  blueGrey10,
  blueGrey20,
  blueGrey25,
  blueGrey50,
  blueGrey75,
  blueGrey100,
  blueGrey200,
  blueGrey300,
  blueGrey400,
  blueGrey500,
  blueGrey600,
  blueGrey700,
  blueGrey800,
  blueGrey880,
  blueGrey900,
  blueGrey920,
  blueGrey940,
  blueGrey990,
  blueGrey999,
  white,
  black,
} as const;
